import formInit from './form';
import AOS from 'aos';
import mobileMenuInit from './mobileMenu';

AOS.init({
  offset: 300,
  once: true
});

window.addEventListener('load', () => {
  mobileMenuInit();
  formInit();

});

