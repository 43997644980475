import JustValidate from 'just-validate';

async function sendMail(data) {

    const response = fetch(
        '/send-mail',
        {
            method: 'post',
            body: data
        }
    ).then(response => response.json())

    return response;

}

function getFormData(form) {
    const data = new FormData(form);
    return data;
}

function echoFormMessage(response) {
    const messageContainer = document.querySelector('.form-message');
    const responseClass = response.status ? 'positive' : 'negative';
    messageContainer.className = 'form-message';
    messageContainer.classList.add(responseClass);
    messageContainer.textContent = response.message;
}

function toggleLoadingButton(button) {
    button.classList.toggle('loading');
}

function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const data = getFormData(form);
    const submitBtn = form.querySelector('button[type="submit"]');
    toggleLoadingButton(submitBtn);
    try {
        sendMail(data)
            .then(res => {
                echoFormMessage(res);
                if (res === 'true') {
                    btn.setAttribute('disabled', true);
                }
            });
    } catch (e) {
        echoFormMessage(`C'è stato un errore con l'invio. Per favore ricarica la pagina e riprova.`);
    }

    toggleLoadingButton(submitBtn);


}

export default function formInit() {
    //Chiudi popup conferma messaggio inviato al click
    document.querySelector('.form-message').addEventListener('click', (ev) => {
        ev.currentTarget.className = 'form-message';
    })

    const validation = new JustValidate('#contacts-form');

    validation
        .addField('#field1', [
            {
                rule: 'required',
                errorMessage: 'Il nome è necessario',
            },
            {
                rule: 'minLength',
                value: 3,
                errorMessage: 'Il nome è troppo corto',
            },
            {
                rule: 'maxLength',
                value: 30,
                errorMessage: 'Il nome è troppo lungo',
            },

        ])
        .addField('#field2', [
            {
                rule: 'required',
                errorMessage: 'Email is required',
            },
            {
                rule: 'email',
                errorMessage: 'Questa email non è valida',
            },
        ])
        .addField('#field3', [
            {
                rule: 'number',
                errorMessage: 'Il numero di telefono non è un numero'
            },
            {
                rule: 'minLength',
                value: 9,
                errorMessage: 'Il numero di telefono è troppo corto',
            },
            {
                rule: 'maxLength',
                value: 10,
                errorMessage: 'Il numero di telefono è troppo lungo',
            }
        ])
        .addField('#field4', [
            {
                rule: 'minLength',
                value: 5,
                errorMessage: 'Il messaggio è troppo corto',
            },
            {
                rule: 'maxLength',
                value: 1000,
                errorMessage: 'Il messaggio è troppo lungo',
            },
            {
                rule: 'required',
                errorMessage: 'Il messaggio è necessario',
            }

        ])
        .onSuccess((event) => {
            handleSubmit(event);
        });

}