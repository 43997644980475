export default function mobileMenuInit(){
    const mobileBtn = document.getElementById('menu-mobile-btn');
    const mobileMenu = document.getElementById('menu-main-mobile');
    const mobileMainMenu = document.getElementById('menu-main-mobile-list');

    const toggleOpen = ()=>{
        mobileBtn.classList.toggle('open');
        mobileMenu.classList.toggle('open');
    }
    mobileBtn.addEventListener( 'click', toggleOpen);
    [...mobileMainMenu.children].forEach(li => li.addEventListener('click', toggleOpen));
}
